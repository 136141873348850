import Timeline from "./Timeline";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Timeline />} />
                <Route path="/share/:share" element={<Timeline />} />
            </Routes>
        </BrowserRouter>
    );
    }

export default App;