import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const MetaTags = () => {
  const { share } = useParams();
  return (
    <div>
      <Helmet>
        <meta name="theme-color" content="#000000" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@M_aaruaika" />
        <meta name="twitter:creator" content="@M_aaruaika" />
        <meta name="twitter:title" content="Raikaマルコフ連鎖" />
        <meta name="twitter:description" content={share}/>
        <meta name="twitter:image" content={"https://ogp.giratina.net/raika/"+share+".webp"} />
        <meta property="og:url" content="https://raika.giratina.net" />
        <meta property="og:title" content="Raikaマルコフ連鎖" />
        <meta property="og:description" content={share}/>
        <meta property="og:image" content={"https://ogp.giratina.net/raika/"+share+".webp"} />
      </Helmet>
    </div>
  );
};

export default MetaTags;